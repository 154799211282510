.mat-mdc-form-field {
    font-size: 14px;
    &.no-padding {
        .mat-mdc-form-field-wrapper {
            padding-bottom: 0px !important;
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-prefix {
                    color: var(--color-common-grey) !important;
                }
            }
        }
        // new material remove bottom space
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
    // Used to display only first mat-error
    mat-error {
        display: none !important;
        font-size: var(--font-size-common-12);
        &:first-child {
            display: block !important;
        }
        &.mat-mdc-form-field-error {
            display: inline-flex !important;
        }
    }
    .mat-mdc-form-field-subscript-wrapper {
        margin-top: 2px;
    }
}

@-moz-document url-prefix() {
    .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-prefix .mat-mdc-icon-button,
    .mat-mdc-form-field:not(.mat-mdc-form-field-appearance-legacy) .mat-mdc-form-field-suffix .mat-mdc-icon-button {
        display: inline-block !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input[matinput]::-webkit-outer-spin-button,
input[matinput]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[matinput][type='number'] {
    -moz-appearance: textfield;
}

.mat-mdc-form-field {
    &.mat-mdc-form-field-appearance-outline {
        .mat-mdc-form-field-wrapper {
            .mat-mdc-form-field-infix {
                padding: 7px 0 10px 0;
            }
        }
    }
}

// form field search admin
.search-form-field {
    .mat-mdc-form-field-icon-prefix {
        .mat-icon {
            padding: 0 0px 0 10px !important;
        }
    }
}

// multiple error class
.multiple-mat-error {
    .mat-mdc-form-field-subscript-wrapper {
        margin-top: -11px !important;
        margin-bottom: 8px;
        .mat-mdc-form-field-error-wrapper {
            > .mat-mdc-form-field-error {
                flex-direction: column;
            }
        }
    }
}
