.box-shadow-none {
    box-shadow: none !important;
}

// Cursor
.cursor-default {
    cursor: default;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-move {
    cursor: move !important;
}
.cursor-revert {
    cursor: revert !important;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.pointer-none {
    pointer-events: none !important;
}
.overflowX-scroll {
    width: 100%;
    overflow-x: auto;
}
.overflow-x {
    overflow-x: auto;
}

.overflow-y {
    overflow-y: auto;
}
.overflow-hidden {
    overflow: hidden !important;
}

.overflow-dotted {
    @extend %overflow-dotted;
}
.overflow-visible {
    overflow: visible !important;
}
.header-table-sticky {
    position: sticky;
    inset-inline: 0px;
}
/*
// Word
*/

.alph-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e2e6e8;
    font-weight: 600;
    color: var(--color-common-white);
}

textarea.disable-resize {
    resize: none !important;
}

// Mat Divider

.mat-mdc-divider {
    border-color: var(--color-common-border) !important;
}

// Change tooltip background color
.mat-mdc-tooltip {
    background-color: var(--color-common-tooltip-bg) !important;
    word-break: break-word !important;
    white-space: normal !important;
}

// Z-index
.overlay-z-index {
    z-index: 100 !important;
}

// borders
.border-none {
    border: 0px !important;
}
.border {
    border: 1px solid var(--color-common-border);
}
.border-top {
    border-top: 1px solid var(--color-common-border);
}
.border-bottom {
    border-bottom: 1px solid var(--color-common-border);
}
.border-right {
    border-right: 1px solid var(--color-common-border);
}
.border-left {
    border-left: 1px solid var(--color-common-border);
}
.border-dashed {
    border: 1px dashed var(--color-common-border);
}

// border radius
.rounded-0 {
    border-radius: 0px !important;
}
.rounded-4 {
    border-radius: var(--border-common-radius-4) !important;
}
.rounded-8 {
    border-radius: var(--border-common-radius) !important;
}
.right-top-rounded-8 {
    border-top-right-radius: var(--border-common-radius);
}
.right-bottom-rounded-8 {
    border-bottom-right-radius: var(--border-common-radius);
}
.left-top-rounded-8 {
    border-top-left-radius: var(--border-common-radius);
}
.left-bottom-rounded-8 {
    border-bottom-left-radius: var(--border-common-radius);
}
.right-border-radius-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.left-border-radius-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.border-radius-50 {
    border-radius: var(--border-common-radius-50) !important;
}

.wh-img {
    width: 30px;
    height: 30px;
}

// Code Snippet
.code-snippet-view {
    pre {
        min-height: 300px;
        @include custom-scroll-bar(
            $scrollbarThumbColor: var(--color-common-grey),
            $scrollbarTrackColor: var(--color-common-smoke),
            $scrollbarWidth: 10px,
            $scrollbarTrackRadius: 0px
        );
    }
}

.note {
    padding: 8px;
    background: var(--color-hello-primary-light);
    width: fit-content;
    border-radius: var(--border-common-radius);
    display: flex;
    gap: 8px;
}
.box-sizing-border {
    box-sizing: border-box !important;
}
.box-sizing-content {
    box-sizing: content-box !important;
}

// Add Scrollbar in Markdown with custom color
markdown pre {
    overflow: auto;
    @include custom-scroll-bar(
        $scrollbarThumbColor: rgba(255, 255, 255, 0.2),
        $scrollbarTrackColor: rgba(255, 255, 255, 0.4),
        $scrollbarWidth: 8px,
        $scrollbarTrackRadius: 4px
    );
}
