@import '../theme/theme-colors';
.content-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: transparent;
    .loading-box {
        min-width: 130px;
        width: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: auto;
        z-index: 999;
        transform: translate(-50%, -50%);
        background-color: var(--color-common-white);
        box-shadow:
            0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 5px 8px 0px rgba(0, 0, 0, 0.14),
            0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        padding: 6px;
        border-radius: 6px;
        color: var(--color-common-black);
        display: flex;
        justify-content: center;
        .mat-mdc-progress-spinner {
            width: 20px !important;
            height: 20px !important;
            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }
        span {
            margin-left: 12px;
            font-size: var(--font-size-common-14);
            color: #222222;
        }
    }
}

.option-loader {
    position: absolute;
    top: 3px;
    right: 4px;
    background-color: var(--color-common-white);
    border-radius: 50%;
    .mat-mdc-progress-spinner {
        width: 20px !important;
        height: 20px !important;
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
}
.center-loader {
    position: absolute;
    top: 50%;
    right: 50%;
    background-color: var(--color-common-white);
    border-radius: 50%;
    .mat-mdc-progress-spinner {
        width: 20px !important;
        height: 20px !important;
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
}
.mat-mdc-progress-spinner.white circle,
.mat-mdc-spinner.white circle {
    stroke: var(--color-common-white) !important;
}
