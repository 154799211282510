body.light-theme {
    //Common color variables
    --color-common-dark: #212528;
    --color-common-slate: #3f4346;
    --color-common-rock: #5d6164;
    --color-common-grey: #8f9396;
    --color-common-cloud: #c1c5c8;
    --color-common-smoke: #d5d9dc;
    --color-common-white: #ffffff;
    --color-common-silver: rgba(123, 127, 130, 0.07);

    --color-common-bg: var(--color-common-white);
    --color-common-text: var(--color-common-slate);
    --color-common-text-2: var(--color-common-rock);
    --color-common-border: var(--color-common-smoke);
    --color-common-placeholder: var(--color-common-grey);
    --color-common-icon: var(--color-common-rock);
    --color-common-input-border: var(--color-common-smoke);
    --color-common-tooltip-bg: var(--color-common-dark);
    --color-common-dialog-bg: var(--color-common-white);
    --color-common-chip-bg: rgba(123, 127, 130, 0.09);

    --color-common-graph-bg: rgba(123, 127, 130, 0.09);
    --color-common-bg-light: rgba(123, 127, 130, 0.09);
    --color-common-bg-lighter: rgba(123, 127, 130, 0.1);
    --color-common-bg-lighter-hover: rgba(123, 127, 130, 0.3);
    --color-common-scrollbar: 0, 0, 0;
    --color-common-hover: var(--color-common-silver);

    --color-common-app-bg: #fafafa;

    --color-button-text: var(--color-common-slate);
    --color-button-bg: var(--color-common-bg-lighter);
    --color-button-hover: var(--color-common-bg-lighter-hover);

    // Primary Color
    --color-common-primary: #3f51b5;
    --color-common-primary-light: #e2f0ff;
    --color-common-primary-dark: #29379d;
    --color-common-primary-light-hover: rgba(77, 160, 255, 0.32);

    // --color-common-primary: #1d6ec0;
    // --color-common-primary-light: rgba(33, 125, 217, 0.13);
    // --color-common-primary-dark: #0d4680;

    // Box Shadow
    --color-common-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%);

    // Table Color variables
    --color-table-head: var(--color-common-slate);
    --color-table-cell: var(--color-common-slate);
    --color-table-head-border: var(--color-common-smoke);
    --color-table-cell-border: var(--color-common-smoke);

    // Sidenav colors variables
    --color-sidenav-expanded-background: var(--color-common-primary-light);
    --color-sidenav-border: #e1e4e8;
    --color-sidenav-icon: var(--color-common-grey);

    --color-sidenav-menu-link-background: var(--color-common-white);
    --color-sidenav-menu-link-icon: var(--color-common-slate);
    --color-sidenav-menu-link-text: var(--color-common-slate);

    --color-sidenav-menu-hover-link-background: var(--color-common-primary);
    --color-sidenav-menu-hover-link-text: var(--color-common-white);
    --color-sidenav-menu-hover-link-icon: var(--color-common-white);

    --color-sidenav-menu-active-link-background: var(--color-common-primary);
    --color-sidenav-menu-active-link-text: var(--color-common-white);
    --color-sidenav-menu-active-link-icon: var(--color-common-white);

    --color-common-sidenav-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);

    --color-common-sip-primary: #1e75ba;
    --color-common-sip-primary-dark: #1258a3;
}
