/* You can add global styles to this file, and also import other style files */

/* Plus imports for other components in your app.*/
@import 'src/assets/scss/base/_fonts.scss';
/* Theme*/
@import 'src/assets/admin-scss/themem3-theme.scss';

@import 'src/assets/scss/base/_default-variables.scss';

@import 'src/assets/scss/base/_light-theme.scss';
@import 'src/assets/scss/base/_dark-theme.scss';
// @import 'scss/base/common-theme.scss';

/* Reset*/
@import './assets/scss/base/reset';

/* Layout*/
@import './assets/scss/layout/display';
@import './assets/scss/layout/flex';
@import './assets/scss/layout/sizing';
@import './assets/scss/layout/spacing';
@import './assets/scss/layout/grid';
@import './assets/scss/layout/text';
@import './assets/scss/layout/positions';

/* Component*/
@import './assets/scss/component/modal'; // Last me check krna hai

/* Common Class Name*/
@import './assets/scss/utils/utils-classes';

/* Backgound Colors*/
@import './assets/scss/component/bg-colors';

/*Components*/
@import './assets/scss/component/filter';
@import './assets/scss/component/toast';
@import './assets/scss/component/table';
@import './assets/admin-scss/component/table';
@import './assets/scss/component/loader';
@import './assets/scss/component/icon';
@import './assets/scss/component/card'; // need to check in last
@import './assets/admin-scss/component/form-field';
@import './assets/scss/component/pagination';
@import './assets/scss/component/tabs';
@import './assets/scss/component/buttons';
@import './assets/scss/component/status';
@import './assets/scss/component/tooltip';
@import './assets/scss/component/side-dialog';
@import './assets/scss/component/menu';
@import './assets/scss/component/material-base-component/bottom-sheet';
@import './assets/scss/component/checkbox';
@import './assets/admin-scss/component/sidenav';
@import './assets/scss/component/select-option';
@import './assets/scss/component/chart';
@import './assets/scss/component/expansion-panel';
// for date picker
@import './assets/scss/component/date-picker';
@import './assets/scss/component/material-base-component/mat-list';
@import './assets/scss/component/markdown';

/*Main*/
.app-content {
    padding: 16px;
    min-height: 100%;
    background-color: var(--color-common-bg);
    height: calc(100vh - 64px);
    overflow-y: auto;
    @media (max-width: 768px) {
        overflow-x: auto;
    }
}
