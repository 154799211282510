/*
// Material Icons
*/
.mat-icon-12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    line-height: 12px !important;
}
.mat-icon-14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    line-height: 14px !important;
}
.mat-icon-16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important;
}
.mat-icon-18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    line-height: 18px !important;
}
.mat-icon-20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
}
.mat-icon-22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    line-height: 22px !important;
}
.mat-icon-24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
}
.mat-icon-26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important;
}
.mat-icon-28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
}
.mat-icon-32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
}
.mat-icon-40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}
/*
// With Opacity
*/
.icon-muted {
    color: var(--color-common-grey);
}

// admin-panel-dashboard-two.component
.mat-icon-circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    &.failed {
        background-color: var(--color-email-primary-light);
    }
    &.delivered {
        background-color: var(--color-whatsApp-primary-light);
    }
    &.bounced {
        background-color: var(--color-common-graph-bg);
    }
    &.unsubscribe {
        background-color: var(--color-common-graph-bg);
    }
    &.ip-count {
        background-color: var(--color-voice-primary-light);
    }
    &.domain {
        background-color: var(--color-short-url-primary-light);
    }
    &.total-users {
        background-color: var(--color-common-primary-light);
    }
    &.source {
        background-color: var(--color-whatsApp-primary-light);
    }
    &.email-stop {
        background-color: var(--color-email-primary-light);
    }
}
