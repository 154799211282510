.line-chart {
    text {
        fill: var(--color-common-text);
        font-size: 10px !important;
        line-height: 12px;
    }
}

.chart-legend {
    .legend-wrap {
        .legend-labels {
            display: flex;
            justify-content: center;
            background-color: transparent !important;
        }
    }
}

.mat-mdc-card {
    // not added for editor overlay issue
    canvas:not(.decorationsOverviewRuler) {
        width: 100% !important;
        height: 100% !important;
    }
}

// Shift Form admin to client
canvas:not(.decorationsOverviewRuler) {
    width: 100% !important;
}

//
.highcharts-credits {
    display: none;
}
