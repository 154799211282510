.table-scroll {
    height: calc(100vh - 258px);
    overflow-y: auto;
    display: block;
    @media (max-width: 660px) {
        height: calc(100vh - 240px);
        overflow-y: auto;
        display: block;
    }
}
table {
    .mat-mdc-cell {
        padding: 4px 16px !important;
    }
}

tr.expandable-row-table {
  height: 0px;
}
