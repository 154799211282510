.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-sticky {
    position: sticky;
}
.position-fixed {
    position: fixed;
}

/*
// Sticky Fixed Header
*/
.sticky-fixed {
    position: sticky !important;
    top: 0;
    z-index: 9999;
    background-color: var(--color-common-bg);
    @media only screen and (max-width: 768px) {
        position: relative !important;
    }
}

.sticky-head-w {
    position: sticky !important;
    top: 0;
    z-index: 9999;
    background-color: var(--color-common-white);
}
.sticky-bottom {
    position: sticky;
    bottom: 0px;
}
