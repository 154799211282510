.bg-white {
    background-color: var(--color-common-white) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-gray {
    background-color: var(--color-common-bg);
}
.bg-light-grey {
    background-color: var(--color-common-app-bg) !important;
}
.bg-green {
    background-color: var(--color-common-green) !important;
    color: var(--color-common-white) !important;
}
.bg-primary-light {
    background-color: var(--color-common-primary-light) !important;
}
.bg-primary {
    background-color: var(--color-common-primary);
}
.bg-silver {
    background-color: var(--color-common-silver);
}

.bg-success-light {
    background-color: var(--color-whatsApp-primary-light) !important;
}
.bg-failed-light {
    background-color: var(--color-email-primary-light) !important;
}

// Background hover
.bg-hover-silver-light {
    &:hover {
        background-color: var(--color-common-bg-lighter);
    }
}
// Backdrop hello attachment backdrop
.attachment-preview-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-silver {
    background-color: var(--color-common-app-bg) !important;
}

.bg-hover-primary-light {
    &:hover,
    &.active {
        background-color: var(--color-common-primary-light);
        color: var(--color-common-primary);
    }
}
.bg-btn-primary {
    background-color: var(--color-common-primary);
    &:hover {
        background-color: var(--color-common-primary-dark);
    }
}

.bg-dark-grey {
    background-color: var(--color-common-left-sidebav-bg);
}
