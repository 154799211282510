@import '../utils/mixins/common-utils';

table.mat-mdc-table {
    width: 100%;
}

// Table Scroll
.table-scroll {
    // Do not remove this commented CSS. If we use scroll in the table in the future, we will use this class or CSS
    // height: calc(100vh - 197px + var(--toolbar-height) - var(--sip-calling-height));
    // height: calc(100vh - 40px - 57px - var(--toolbar-height) - var(--sip-calling-height));
    // shubham keshari did this for domain setting changes
    // height: calc(100vh - 205px + var(--toolbar-height) - var(--sip-calling-height));
    // overflow-y: auto;
    display: block;
    @media only screen and (max-width: 992px) {
        height: 100%;
        overflow-y: visible;
        display: block;
    }
    // email-dashboard-height
    &.short-table {
        height: 300px;
        overflow-y: auto;
    }
}
.table-scroll-v2 {
    // height: calc(100vh - 197px - var(--sip-calling-height));
    // overflow-y: auto;
    display: block;
    // @media only screen and (max-width: 660px) {
    //     height: calc(100vh - 160px - var(--toolbar-height));
    //     overflow-y: auto;
    //     display: block;
    // }
}

// Table scroll if there is not any header

.table-scroll-header {
    // height: calc(100vh - 170px - var(--sip-calling-height));
    // overflow-y: auto;
    display: block;

    .mat-mdc-table {
        .mat-mdc-header-row {
            .mat-mdc-header-cell {
                border-top: 0px !important;
            }
        }
    }
}

.default-prime-table {
    .p-datatable {
        font-weight: 400;
        font-family: var(--msg-font-family);
        font-size: var(--font-size-common-14);
        color: var(--color-table-cell);
        border-bottom-color: var(--color-table-cell-border) !important;
        .p-datatable-tbody > tr > td {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            font-weight: 400;
            font-size: var(--font-size-common-14);
            color: var(--color-table-cell);
            &:first-child {
                padding-left: 24px;
            }
            &:last-child {
                padding-right: 24px;
            }
        }
        .p-datatable-thead > tr > th {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-common-12);
            color: var(--color-table-head) !important;
            &:first-child {
                padding-left: 24px;
            }
            &:last-child {
                padding-right: 24px;
            }
            &:hover .p-column-resizer {
                background-color: var(--color-common-dark) !important;
            }
            @media (hover: hover) {
                &:hover .prime-table-sort-visibility {
                    visibility: visible !important;
                }
            }
            @media (hover: none) {
                .prime-table-sort-visibility {
                    visibility: visible !important;
                }
            }
        }
        .p-datatable-thead {
            height: 50px;
        }
        .p-column-resizer {
            border: 3px solid white !important;
            background-color: var(--color-common-graph-bg) !important;
            &:hover {
                background-color: var(--color-common-dark) !important;
            }
        }
        table {
            table-layout: fixed;
            width: 100% !important;
        }
    }
    .p-scroller {
        @include custom-horizontal-scroll-bar(
            $scrollbarThumbColor: var(--color-common-rock),
            $scrollbarTrackColor: var(--color-common-silver),
            $scrollbarWidth: 10px,
            $scrollbarTrackRadius: 0px,
            $scrollbarTrackBorderTop: 1px
        );
    }
}

// comment for default material table
// Default table
// .default-table {
//     background-color: transparent !important;
//     box-shadow: none;
//     width: 100%;
//     border-radius: var(--border-common-radius);
//     // padding: 1px;
//     thead {
//         border-top-left-radius: var(--border-common-radius);
//         border-top-right-radius: var(--border-common-radius);
//     }
//     .mat-mdc-header-row {
//         background-color: var(--color-common-bg) !important;
//         border-top-left-radius: var(--border-common-radius);
//         border-top-right-radius: var(--border-common-radius);
//         .mat-mdc-header-cell {
//             font-weight: var(--font-weight-bold);
//             font-size: var(--font-size-common-12);
//             color: var(--color-table-head) !important;
//             border-bottom-color: var(--color-table-head-border) !important;
//             border-top-left-radius: var(--border-common-radius);
//             &:last-child {
//                 border-top-right-radius: var(--border-common-radius);
//             }
//             &:not(:first-child) {
//                 padding-left: 16px;
//             }
//         }
//         .col-filter {
//             position: relative;
//             .filter-apply {
//                 width: 6px;
//                 height: 6px;
//                 display: inline-block;
//                 background-color: var(--color-email-primary);
//                 border-radius: 50%;
//                 position: absolute;
//                 top: 14px;
//                 right: 8px;
//             }
//         }
//         .mat-mdc-sort-header-container {
//             border: none !important;
//         }
//     }
//     .mat-mdc-row {
//         .mat-mdc-cell {
//             font-weight: normal;
//             font-size: var(--font-size-common-14);
//             color: var(--color-table-cell);
//             border-bottom-color: var(--color-table-cell-border) !important;
//             @media only screen and (min-width: 768px) {
//                 padding-left: 16px;
//                 &:last-child {
//                     padding-right: 24px;
//                 }
//                 &:first-child {
//                     padding-left: 24px;
//                 }
//             }
//             &:not {
//                 &:first-of-type {
//                     padding-left: 8.8px;
//                 }
//             }
//         }
//         &.mat-mdc-row-bold {
//             .mat-mdc-cell {
//                 font-weight: 600;
//             }
//         }
//         &.highlight {
//             background-color: var(--color-common-bg-lighter);
//         }
//         &.disabled-row {
//             .mat-mdc-cell {
//                 color: var(--color-common-cloud);
//             }
//         }
//         &.hover-action {
//             .actions {
//                 opacity: 0;
//             }
//             .hide-actions {
//                 display: block;
//             }
//             @media (hover: hover) {
//                 &:hover {
//                     .actions {
//                         opacity: 1;
//                     }
//                     .hide-actions {
//                         display: none;
//                     }
//                 }
//             }
//             @media (hover: none) {
//                 .actions {
//                     opacity: 1;
//                 }
//             }
//             &.active-row {
//                 background: var(--color-common-silver);
//                 .actions {
//                     opacity: 1;
//                     display: block;
//                 }
//             }
//         }
//         @media (hover: hover) {
//             &:hover:not(.remove-hover-effect, .mat-mdc-no-data-row) {
//                 background: var(--color-common-silver);
//                 .inner-table {
//                     .mat-mdc-row,
//                     .add-version-btn-wpr {
//                         &:hover {
//                             background-color: transparent;
//                         }
//                     }
//                 }
//             }
//         }
//         &.mat-mdc-no-data-row {
//             .mat-mdc-cell {
//                 padding: 0px;
//             }
//         }
//         &.last-child {
//             .mat-mdc-cell {
//                 border-bottom: 0px;
//             }
//         }
//         &:last-child {
//             border-bottom-left-radius: var(--border-common-radius);
//             border-bottom-right-radius: var(--border-common-radius);
//             .mat-mdc-cell {
//                 border-bottom: 0px;
//             }
//         }
//     }
//     .mat-mdc-footer-row {
//         background-color: var(--color-common-white);
//         .mat-mdc-footer-cell {
//             font-weight: var(--font-weight-medium);
//             font-size: var(--font-size-common-12);
//             color: var(--color-table-cell);
//             white-space: nowrap;
//             border-top: 1px solid var(--color-common-border);
//             border-bottom-width: 0px;
//             @media only screen and (min-width: 768px) {
//                 padding-left: 16px;
//                 &:last-child {
//                     padding-right: 24px;
//                 }
//                 &:first-child {
//                     padding-left: 24px;
//                 }
//             }
//             &:not {
//                 &:first-of-type {
//                     padding-left: 8.8px;
//                 }
//             }
//         }
//     }
//     &.header-right-border {
//         .mat-mdc-header-row {
//             .mat-mdc-header-cell {
//                 &:not(:last-child) {
//                     border-right: 1px solid var(--color-table-head-border) !important;
//                 }
//             }
//         }
//     }
//     &.header-height {
//         .mat-mdc-header-row {
//             height: 46px;
//         }
//     }

//     &.table-md {
//         .mat-mdc-header-row {
//             height: 36px;
//         }
//         .mat-mdc-row {
//             height: 38px;
//         }
//     }
// }

@media screen and (max-width: 768px) {
    .mat-mdc-table {
        &.responsive-table {
            background-color: transparent !important;
            .mat-mdc-row {
                box-shadow: 0 0 5px rgb(0 0 0 / 10%);
                display: block;
                min-height: 30px;
                height: auto;
                border-radius: var(--border-common-radius);
                margin-bottom: 16px;
                background-color: var(--color-common-white);
                &:not(.mat-mdc-no-data-row) {
                    display: grid;
                    grid-template-columns: auto auto;
                }
                .mat-mdc-cell {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    word-wrap: break-word;
                    min-height: inherit;

                    border-bottom: 1px solid var(--color-table-head-border);
                    display: block;
                    text-align: left;
                    font-weight: 500;
                    height: auto;
                    padding: 6px 8px;
                    display: grid;
                    font-size: var(--font-size-common-12);
                    &:before {
                        content: attr(data-label);
                        float: left;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: var(--font-size-common-10);
                        color: var(--color-common-rock);
                    }
                    // &:last-child {
                    //     border-bottom: 0;
                    // }
                    &:nth-child(even) {
                        text-align: right;
                        // &:after {
                        //     content: attr(data-label);
                        //     float: left;
                        //     text-transform: uppercase;
                        //     font-weight: 500;
                        //     font-size:var(--font-size-common-10);
                        //     color: var(--color-common-grey);
                        // }
                        // &:before{
                        //     display: none;
                        // }
                    }
                    &.action-column-m {
                        height: 62px;
                    }
                    &.justify-content-end {
                        justify-content: end;
                        .mat-mdc-icon-button {
                            margin-left: auto;
                        }
                    }
                    &.justify-content-start {
                        justify-content: flex-start;
                    }
                }
            }
            .mat-mdc-footer-row {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-height: 30px;
                height: auto;
                .mat-mdc-footer-cell {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    word-wrap: break-word;
                    min-height: inherit;
                }
            }
            .mat-mdc-header-row {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-height: 48px;
                height: auto;
                .mat-mdc-header-cell {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    word-wrap: break-word;
                    min-height: inherit;
                    &:first-child {
                        padding-left: 8px;
                    }
                }
                ::ng-deep.mat-mdc-sort-header-container {
                    .mat-mdc-sort-header-arrow {
                        opacity: 1 !important;
                    }
                }
            }

            thead {
                display: none;
            }
        }
    }
}

.v-align-middle {
    vertical-align: middle;
}
.v-align-top {
    vertical-align: top;
}

// Data table
.data-table {
    border: 1px solid var(--color-common-border);
    border-radius: var(--border-common-radius);
    .data-table-layout {
        th,
        td {
            border-collapse: collapse;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            white-space: nowrap;
        }
        th {
            border-bottom: 1px solid var(--color-common-border);
            padding-inline: 7px;
            padding-block: 8px;
        }
        td {
            padding-inline: 7px;
            padding-top: 8px;
        }
        tbody {
            tr {
                &:last-child {
                    td {
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }
}
// Expandable table Ex: template table
.expandable-table {
    .mat-mdc-row {
        &:not(.expandable-row-table) {
            cursor: pointer;
            .mat-mdc-cell {
                border-bottom-width: 0px !important;
            }
        }
        &.expandable-row-table {
            height: 0;
        }
        .mat-mdc-cell {
            .expandable-element-detail {
                overflow: hidden;
                display: flex;
                background-color: var(--color-common-bg);
            }
        }
    }
}
.automation-table {
    .mat-mdc-sort-header-container {
        gap: 8px;
        .mat-mdc-sort-header-arrow {
            order: 0;
        }
        .mat-mdc-sort-header-content {
            order: 1;
        }
    }
}

// otp-mobile-sdk-prop-table
.custom-table {
    tr.mat-mdc-header-row {
        height: 40px;
    }

    .mat-mdc-header-cell {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--color-common-border) !important;
        padding-left: 24px;
    }
    tr.mat-mdc-row {
        height: 48px;
        .mat-mdc-cell {
            border-bottom: 1px solid var(--color-common-border) !important;
            padding-left: 24px;
        }
        &:last-child {
            .mat-mdc-cell {
                border-bottom: 0px !important;
            }
        }
    }
}

// Below code Shift from admin to client
.mat-mdc-sort-hide-on-disable {
    .mat-mdc-sort-header-container .mat-mdc-sort-header-arrow {
        display: none !important;
    }
}
.mat-sort-hide-on-disable {
    .mat-sort-header-container .mat-sort-header-arrow {
        display: none !important;
    }
}
