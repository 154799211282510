.mat-mdc-paginator {
    &.default-mat-paginator {
        background-color: transparent !important;
        .mat-mdc-form-field {
            .mat-mdc-form-field-wrapper {
                padding-bottom: 6px !important;
            }
            .mat-mdc-form-field-wrapper {
                margin-bottom: 0 !important;
            }
        }
        .mat-mdc-paginator-range-label {
            @media only screen and (max-width: 768px) {
                margin: 0 10px 0 10px;
            }
        }
    }
}

// Goto pagination
.mat-mdc-paginator-outer-container {
    .mat-mdc-select-value {
        line-height: 16px;
    }
    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
        text-transform: capitalize !important;
        font-size: var(--font-size-common-12);
        color: var(--color-common-text);
    }
    .mat-mdc-form-field-wrapper {
        padding-bottom: 3.5px;
    }
    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
        padding: 0px 0 8px 0 !important;
    }
    .mat-mdc-paginator-page-size-select {
        margin: 0 !important;
    }
}
.go-to-pagination {
    border-top: 1px solid var(--color-table-cell-border);
    .mat-mdc-paginator {
        box-shadow: none !important;
        border-top: 0px;
        .mat-mdc-paginator-page-size-label {
            @media (max-width: 660px) {
                display: none;
            }
        }
    }
    .go-to-container {
        display: flex;
        align-items: baseline;
        height: 39px;

        .go-to-label {
            margin: 0 4px;
            font-size: 12px;
            color: var(--color-common-text);
            font-family: var(--font-family-common);
            @media (max-width: 460px) {
                display: none;
            }
        }
        .mat-mdc-form-field {
            width: 56px;
            font-size: 12px;
            .mat-mdc-form-field-wrapper {
                padding-bottom: 0px !important;
                margin-top: 0px;
                .mat-mdc-text-field-flex {
                    font-size: 12px;
                    .mat-mdc-form-field-infix {
                        padding: 4px 0 9px 0 !important;
                        .mat-mdc-select-arrow-wrapper {
                            transform: translateY(-7%) !important;
                        }

                        .mat-mdc-select-value-text {
                            color: var(--color-common-text);
                            font-size: 11px;
                        }
                    }
                }
            }
            .mdc-text-field--outlined,
            .mdc-text-field--no-label {
                .mat-mdc-form-field-infix {
                    padding: 10px 0 10px 0;
                    height: 42px;
                    min-height: 42px;
                }
            }
            .mdc-text-field--outlined {
                padding: 0 10px !important;
            }
            .mat-mdc-select-value {
                font-size: var(--font-size-common-12);
            }
        }
    }
    .mat-mdc-paginator {
        .mat-mdc-paginator-container {
            @media (max-width: 460px) {
                padding-right: 0px;
                .mat-mdc-paginator-page-size {
                    margin-right: 0px !important;
                }
                .mat-mdc-paginator-range-label {
                    margin-right: 0px !important;
                }
                .mat-mdc-paginator-navigation-previous,
                .mat-mdc-paginator-navigation-next {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}

.hide-pagination-arrows {
    .mat-mdc-paginator {
        .mat-mdc-paginator-container {
            .mat-mdc-paginator-navigation-previous,
            .mat-mdc-paginator-navigation-next {
                display: none !important;
            }
        }
    }
}
.mat-show-page-number {
    > span {
        overflow: visible !important;
    }
    .mat-pseudo-checkbox-minimal {
        margin-left: 8px !important;
    }
}
.mat-paginator-option {
    .mat-pseudo-checkbox-minimal {
        margin-left: 8px !important;
    }
}
