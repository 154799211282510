@mixin generateIconBtn($btnSize, $iconSize, $iconColor) {
    width: $btnSize !important;
    height: $btnSize !important;
    min-height: $btnSize;
    line-height: $btnSize;
    .mat-mdc-button-wrapper {
        .mat-mdc-icon {
            height: $iconSize;
            width: $iconSize;
            line-height: $iconSize;
            font-size: $iconSize;
            color: $iconColor;
        }
    }
}

@mixin iconBtnHover($iconColor, $hoverBgColor, $hoverIconColor, $isActiveClass: false) {
    color: $iconColor !important;
    &:hover {
        background-color: $hoverBgColor !important;
        mat-icon {
            color: $hoverIconColor !important;
        }
    }
    @if $isActiveClass {
        &.active {
            background-color: $hoverBgColor !important;
            mat-icon {
                color: $hoverIconColor !important;
            }
        }
    }
}

@mixin btnHover($defaultTextColor, $defaultBgColor, $hoverBgColor, $hoverTextColor: null) {
    background-color: $defaultBgColor;
    color: $defaultTextColor;
    &:hover {
        background-color: $hoverBgColor;
        @if $hoverTextColor {
            color: $hoverTextColor;
        }
        .mat-mdc-button-focus-overlay {
            opacity: 0 !important;
        }
    }
}

// Responsive media query mixin

@mixin media-breakpoint-down($breakpoint) {
    @if $breakpoint == 'phone' {
        // Applies to x-small devices (portrait phones, less than 576px)
        @media (max-width: 575.98px) {
            @content;
        }
    } @else if $breakpoint == 'tablet' {
        // Applies to small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
            @content;
        }
    } @else if $breakpoint == 'desktop' {
        // Applies to medium devices (tablets, less than 992px)
        @media (max-width: 991.98px) {
            @content;
        }
    } @else if $breakpoint == 'lg-desktop' {
        // Applies to large devices (desktops, less than 1200px)
        @media (max-width: 1199.98px) {
            @content;
        }
    } @else if $breakpoint == 'xlg-desktop' {
        // Aapplies to xlg-large devices (large desktops, less than 1400px)
        @media (max-width: 1399.98px) {
            @content;
        }
    }
}

@mixin media-breakpoint-up($breakpoint) {
    @if $breakpoint == 'phone' {
        // Applies to x-small devices (portrait phones, less than 576px)
        @media (min-width: 576px) {
            @content;
        }
    } @else if $breakpoint == 'tablet' {
        // Applies to small devices (landscape phones, less than 768px)
        @media (min-width: 768px) {
            @content;
        }
    } @else if $breakpoint == 'desktop' {
        // Applies to medium devices (tablets, less than 992px)
        @media (min-width: 992px) {
            @content;
        }
    } @else if $breakpoint == 'lg-desktop' {
        // Applies to large devices (desktops, less than 1200px)
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $breakpoint == 'xlg-desktop' {
        // Aapplies to xlg-large devices (large desktops, less than 1400px)
        @media (min-width: 1400px) {
            @content;
        }
    }
}

// 400, 460
// 500, 540, 550, 576,
// 600, 620, 660, 650-60, 668,
// 700, 767, 768
// 900, 992
// 1024,  1200, 1300, 1400,

@mixin create-toggle-appearance-button($appearanceType) {
    @if $appearanceType == 'outline' {
        &.appearance-outline {
            border: 1px solid var(--color-common-border) !important;
            .mat-mdc-button-toggle {
                background-color: transparent;
            }
        }
    } @else if $appearanceType == 'fill-white' {
        &.appearance-fill-white {
            border: 1px solid var(--color-common-border) !important;
            .mat-mdc-button-toggle {
                background-color: var(--color-common-white);
            }
        }
    } @else if $appearanceType == 'fill-grey' {
        &.appearance-fill-grey {
            border: 1px solid var(--color-common-border) !important;
            .mat-mdc-button-toggle {
                background-color: var(--color-common-smoke);
            }
        }
    } @else if $appearanceType == 'icon-type' {
        &.icon-type-button {
            .mat-mdc-button-toggle {
                min-width: auto;
            }
        }
    }
}

// Toggle Button Sizes
@mixin toggle-button-size($buttonSize, $buttonWidth, $buttonHeight: null) {
    &.toggle-button-#{$buttonSize} {
        .mat-mdc-button-toggle {
            min-width: $buttonWidth;
            @if $buttonHeight {
                .mat-mdc-button-toggle-button {
                    .mat-mdc-button-toggle-label-content {
                        line-height: $buttonHeight !important;
                    }
                }
            }
        }
    }
}

// Calculate height
//  Params:
//  $totalHeight
//  $totalHeightUnit,
//  $offsetHeight
//  $removeSipCallHeight
@mixin calculateHeight($totalHeight: 100, $totalHeightUnit: vh, $offsetHeight, $removeSipCallHeight: false) {
    @if $removeSipCallHeight == true {
        height: calc(#{$totalHeight}#{$totalHeightUnit} - #{$offsetHeight}px);
    } @else {
        height: calc(#{$totalHeight}#{$totalHeightUnit} - #{$offsetHeight}px - var(--sip-calling-height));
    }
}

// Keyframes with vernder prefixes
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

// Issue :: hover work on Desktop but not work on mobile
// Soluations :: When mobile then directly show show and when desktop then hover work

@mixin checkDeviceForHover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    @media (hover: none) {
        @content;
    }
}
/*----------------------------------------------------------------
/* Custom Scrollbar & It's Params
/*----------------------------------------------------------------
/* $scrollbarThumbColor : For scrollbar thumb color
/* $scrollbarTrackColor : For scrollbar track color
/* $scrollbarWidth : For scrollbar width
/* $scrollbarTrackRadius: For scrollbar track radius
/*
*/
@mixin custom-scroll-bar($scrollbarThumbColor, $scrollbarTrackColor, $scrollbarWidth, $scrollbarTrackRadius) {
    &::-webkit-scrollbar {
        width: $scrollbarWidth;
        height: $scrollbarWidth;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $scrollbarTrackColor;
        border-radius: $scrollbarTrackRadius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $scrollbarThumbColor;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbarThumbColor;
    }
}
@mixin custom-horizontal-scroll-bar(
    $scrollbarThumbColor,
    $scrollbarTrackColor,
    $scrollbarWidth,
    $scrollbarTrackRadius,
    $scrollbarTrackBorderTop: 0
) {
    &::-webkit-scrollbar:horizontal {
        height: $scrollbarWidth;
    }
    &::-webkit-scrollbar-track:horizontal {
        background: $scrollbarTrackColor;
        border-radius: $scrollbarTrackRadius;
        @if ($scrollbarTrackBorderTop) {
            border-top: $scrollbarTrackBorderTop solid var(--color-common-border);
        }
    }
    &::-webkit-scrollbar-thumb:horizontal {
        background: $scrollbarThumbColor;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--color-common-slate);
    }
}
