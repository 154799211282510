.tooltip-list {
    white-space: pre;
    font-size: var(--font-size-common-13);
    list-style: none;
    font-size: 14px;
    text-align: left;
}
.custom-tooltip {
    max-width: unset !important;
}
.mat-mdc-tooltip {
    &.white-space-pre-line {
        white-space: pre-line !important;
    }
}
