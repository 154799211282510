@import 'src/assets/scss/utils/mixins/common-utils';
.d-none {
    display: none;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-table {
    display: table !important;
}
.d-table-row {
    display: table-row !important;
}
.d-table-cell {
    display: table-cell !important;
}
.d-flex {
    display: flex !important;
}
.d-inline-flex {
    display: inline-flex !important;
}

.hide-xs {
    vertical-align: baseline !important;

    @media (max-width: 660px) {
        display: none;
    }
}
.d-inline-block {
    display: inline-block;
}
.d-table {
    display: table;
}
.visibility-visible {
    visibility: visible;
}

.visibility-hidden {
    visibility: hidden;
}
