// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@import 'src/assets/scss/theme/_typography.scss';
@import '@angular/cdk/overlay-prebuilt.css';

// Note: Color palettes are generated from primary: #3f51b5
$_palettes: (
    primary: (
        0: #000000,
        10: #00105c,
        20: #08218a,
        25: #1b2f95,
        30: #293ca0,
        35: #3649ac,
        40: #4355b9,
        50: #5d6fd4,
        60: #7789f0,
        70: #97a5ff,
        80: #bac3ff,
        90: #dee0ff,
        95: #f0efff,
        98: #fbf8ff,
        99: #fefbff,
        100: #ffffff,
    ),
    secondary: (
        0: #000000,
        10: #12183d,
        20: #272e53,
        25: #33395e,
        30: #3e446b,
        35: #4a5077,
        40: #565c84,
        50: #6e759e,
        60: #888eb9,
        70: #a3a9d5,
        80: #bec4f2,
        90: #dee0ff,
        95: #f0efff,
        98: #fbf8ff,
        99: #fefbff,
        100: #ffffff,
    ),
    tertiary: (
        0: #000000,
        10: #37003c,
        20: #58055f,
        25: #65176b,
        30: #722578,
        35: #803284,
        40: #8e3e91,
        50: #aa58ac,
        60: #c771c8,
        70: #e48be4,
        80: #ffa9fd,
        90: #ffd6fa,
        95: #ffebf9,
        98: #fff7fa,
        99: #fffbff,
        100: #ffffff,
    ),
    neutral: (
        0: #000000,
        10: #1a1b22,
        20: #2f3037,
        25: #3b3b42,
        30: #46464d,
        35: #525259,
        40: #5e5e65,
        50: #77767e,
        60: #919098,
        70: #abaab3,
        80: #c7c5ce,
        90: #e3e1ea,
        95: #f2eff9,
        98: #fbf8ff,
        99: #fefbff,
        100: #ffffff,
        4: #0d0e14,
        6: #121319,
        12: #1f1f26,
        17: #292930,
        22: #34343b,
        24: #383940,
        87: #dbd9e2,
        92: #e9e7f0,
        94: #efedf6,
        96: #f4f2fc,
    ),
    neutral-variant: (
        0: #000000,
        10: #191b26,
        20: #2e303b,
        25: #393b47,
        30: #454652,
        35: #50515e,
        40: #5c5d6b,
        50: #757684,
        60: #8f909e,
        70: #aaaab9,
        80: #c5c5d4,
        90: #e2e1f1,
        95: #f0efff,
        98: #fbf8ff,
        99: #fefbff,
        100: #ffffff,
    ),
    error: (
        0: #000000,
        10: #410002,
        20: #690005,
        25: #7e0007,
        30: #93000a,
        35: #a80710,
        40: #ba1a1a,
        50: #de3730,
        60: #ff5449,
        70: #ff897d,
        80: #ffb4ab,
        90: #ffdad6,
        95: #ffedea,
        98: #fff8f7,
        99: #fffbff,
        100: #ffffff,
    ),
);

$_rest: (
    secondary: map.get($_palettes, secondary),
    neutral: map.get($_palettes, neutral),
    neutral-variant: map.get($_palettes, neutral-variant),
    error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: $_primary,
            tertiary: $_tertiary,
        ),
        typography: (
            plain-family: var(--font-family-common),
            brand-family: var(--font-family-common),
        ),
    )
);
$dark-theme: mat.define-theme(
    (
        color: (
            theme-type: dark,
            primary: $_primary,
            tertiary: $_tertiary,
        ),
        typography: (
            plain-family: var(--font-family-common),
            brand-family: var(--font-family-common),
        ),
    )
);

@include mat.core();

html {
    // Apply the light theme by default
    .light-theme {
        @include mat.all-component-themes($light-theme);
    }

    .dark-theme {
        @include mat.all-component-themes($dark-theme);
    }
    @include mat.all-component-typographies($custom-typography);
    @include mat.typography-hierarchy($custom-typography);
    // @include mat.button-theme($light-theme);
}
