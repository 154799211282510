@import '../utils/mixins/common-utils';
.client-filter-menu {
    min-width: 400px !important;
    max-width: 400px !important;
    margin-top: 12px;
    box-shadow: 0px 0px 10px #00000029;
    @media only screen and (max-width: 660px) {
        min-width: 80vw !important;
        max-width: 80vw !important;
        max-height: 70vh;
        overflow-y: hidden !important;
    }
    .mat-mdc-menu-content {
        padding: 0px !important;
        @include media-breakpoint-down('desktop') {
            max-height: 60vh;
            overflow-y: auto;
        }
        .date-picker-row {
            padding: 25px;
        }
        .client-filter-content {
            @media (max-width: 992px) {
                max-height: 62vh;
                overflow-y: auto;
            }
        }
        .mat-mdc-form-field {
            &.example-full-width {
                width: 160px !important;
            }
            .mat-mdc-icon {
                width: 16px;
            }
        }
        .action-button {
            padding: 20px 25px;
        }
    }
}

// admin-panel scss
.filter-menu {
    min-width: 400px !important;
    max-width: 400px !important;
    margin-top: 12px;
    @media (max-width: 660px) {
        min-width: 80vw !important;
        max-width: 80vw !important;
        max-height: 70vh;
    }
    .mat-mdc-menu-content {
        padding: 0px !important;
        .date-picker-row {
            padding: 20px 25px 0px 25px;
        }
        .mat-mdc-form-field {
            .mat-mdc-icon {
                width: 16px;
            }
        }
        .action-button {
            padding: 20px 25px;
        }
    }
}
