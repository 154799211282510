@use '@angular/material' as mat;

/*
// Custom Bluex
*/
$custom-blue: (
    50: #e6f2fb,
    100: #a4cff0,
    200: rgba(33, 125, 217, 0.13),
    300: #3794df,
    400: #2286d4,
    500: #1e75ba,
    600: #1a64a0,
    700: #155485,
    800: #11436b,
    900: #0d3351,
    A100: #d8eeff,
    A200: #72c1ff,
    A400: #1b92ef,
    A700: #1c84d6,
    contrast: (
        // 50: $dark-primary-text,
        // 100: $dark-primary-text,
        // 200: $dark-primary-text,
        300: var(--color-common-white),
        400: var(--color-common-white),
        500: var(--color-common-white),
        600: var(--color-common-white),
        700: var(--color-common-white),
        800: var(--color-common-white),
        900: var(--color-common-white),
        // A100: $dark-primary-text,
        // A200: $dark-primary-text,
        A400: var(--color-common-white),
        A700: var(--color-common-white),
    ),
);

/*
// Define a theme.
*/
$primary: mat.m2-define-palette($custom-blue);
$accent: mat.m2-define-palette($custom-blue);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
