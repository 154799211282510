@import '../utils/mixins/common-utils';

.h-100 {
    height: 100% !important;
}
.h-75 {
    height: 75% !important;
}
.h-50 {
    height: 50% !important;
}
.h-25 {
    height: 25% !important;
}
.w-100 {
    width: 100% !important;
}

.w-md-50 {
    @include media-breakpoint-down('tablet') {
        width: 50%;
    }
}
.w-xs-100 {
    @include media-breakpoint-down('phone') {
        width: 100% !important;
    }
}
.w-75 {
    width: 75% !important;
}
.w-60 {
    width: 60% !important;
}
.w-50 {
    width: 50% !important;
}
.w-35 {
    width: 35% !important;
}
.w-25 {
    width: 25% !important;
}
.w-15 {
    width: 15% !important;
}
.w-10 {
    width: 10% !important;
}
.h-auto {
    height: auto !important;
}
.w-auto {
    width: auto !important;
}
.w-content {
    width: fit-content;
}

.mw-auto {
    min-width: auto !important;
}
.min-w-100 {
    max-width: 100% !important;
}
.max-w-100 {
    max-width: 100% !important;
}

.w-xs-50 {
    @media only screen and (max-width: 768px) {
        width: 50% !important;
    }
}
.w-min-300 {
    min-width: 300px;
}
// admin-panel scss

.h-xs-100 {
    @media only screen and (max-width: 768px) {
        height: 100% !important;
    }
}

// This class applying before 768px
@include media-breakpoint-up('tablet') {
    .width-md-10 {
        width: 10px;
    }
    .width-md-20 {
        width: 20px;
    }
    .width-md-70 {
        width: 70px;
    }
    .width-md-100 {
        width: 100px;
    }
    .width-md-120 {
        width: 120px;
    }
    .width-md-200 {
        width: 200px !important;
    }
    .width-md-220 {
        width: 220px !important;
    }
    .width-md-250 {
        width: 250px !important;
    }
    .width-md-300 {
        width: 300px;
    }
    .width-md-400 {
        width: 400px;
    }
    .width-md-500 {
        width: 500px;
    }
    .width-md-600 {
        width: 600px;
    }
    .width-md-700 {
        width: 700px;
    }
    .width-md-800 {
        width: 800px;
    }
}

@include media-breakpoint-down('tablet') {
    .width-sm-10 {
        width: 10px;
    }
    .width-sm-20 {
        width: 20px;
    }
    .width-sm-80 {
        width: 80px;
    }
    .width-sm-100 {
        min-width: 100px;
    }
    .width-sm-120 {
        min-width: 120px;
    }
    .width-sm-200 {
        min-width: 200px;
    }
    .width-sm-250 {
        min-width: 250px;
    }
    .width-sm-300 {
        min-width: 300px;
    }
    .width-sm-400 {
        min-width: 400px;
    }
    .width-sm-500 {
        min-width: 500px;
    }
    .field-width-100 {
        width: 100% !important;
    }
}
