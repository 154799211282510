@import '../theme//theme-colors';

*,
*::before,
*::after {
    box-sizing: border-box;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

a {
    text-decoration: none;
}
body,
html {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: var(--color-common-bg);
    color: var(--color-common-text);
    cursor: default;
    font-family: var(--font-family-common);
    -webkit-text-size-adjust: 100%;
}

pre {
    margin: 0px !important;
    font-size: 14px !important;
}

* {
    scrollbar-width: thin;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: var(--border-common-radius);
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(var(--color-common-scrollbar), 0.1);
    border-radius: var(--border-common-radius);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--color-common-scrollbar), 0.5);
}

/*
// Main
*/
.overflow-overlay {
    overflow: overlay;
    @supports not (overflow: overlay) {
        overflow: auto;
    }
    &:hover {
        ::-webkit-scrollbar-thumb {
            background: rgba(var(--color-common-scrollbar), 0.3);
        }
    }
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
}
// .material-icons-round {
//     font-family: 'Material Icons Round' !important;
// }
.font-family-inherit {
    font-family: inherit !important;
}

.reset-default-props {
    *:not(ul, ol) {
        margin: 0px;
        padding: 0px;
    }
}
