.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}
.align-content-center {
    align-content: center !important;
}
.align-content-between {
    align-content: space-between !important;
}
.align-content-around {
    align-content: space-around !important;
}
.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}
.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-baseline {
    align-self: baseline !important;
}
.align-self-stretch {
    align-self: stretch !important;
}
.m-auto {
    margin-left: auto;
    margin-right: auto;
}

/*
// Responsive
*/

.flex-sm-column {
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .flex-md-column {
        flex-direction: column;
    }
}
.flex-grow-1 {
    flex-grow: 1;
}
.flex-grow-huge {
    flex-grow: 9999;
}

// Space for flex and grid layout
.gap-1 {
    gap: 4px;
}
.gap-2 {
    gap: 8px;
}
.gap-3 {
    gap: 16px;
}
.gap-4 {
    gap: 24px;
}
.gap-5 {
    gap: 32px;
}

// Row Spacing
.row-gap-1 {
    row-gap: 4px;
}
.row-gap-2 {
    row-gap: 8px;
}
.row-gap-3 {
    row-gap: 16px;
}
.row-gap-4 {
    row-gap: 24px;
}
.row-gap-5 {
    row-gap: 32px;
}

// Column Spacing
.col-gap-1 {
    column-gap: 4px;
}
.col-gap-2 {
    column-gap: 8px;
}
.col-gap-3 {
    column-gap: 16px;
}
.col-gap-4 {
    column-gap: 24px;
}
.col-gap-5 {
    column-gap: 32px;
}

// Grid Layout
.d-grid {
    display: grid;
}
