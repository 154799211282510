/*
// Tabs
*/
.mat-mdc-tab-header {
    &.no-border {
        border-width: 0px !important;
    }
    border-color: var(--color-common-border);
    .mat-mdc-tab-labels {
        margin-left: 16px;
        margin-right: 16px;
        .mat-mdc-tab-label {
            font-size: var(--font-size-common-14);
            line-height: 22px;
            font-weight: 400;
            color: var(--color-common-text);
            opacity: 1;
            min-width: none;
            &.mat-mdc-tab-label-active {
                font-weight: var(--font-weight-medium);
                color: var(--color-common-primary);
            }
        }
    }
}
.mat-mdc-tab-group {
    &.default-tab {
        .mat-mdc-tab-header {
            background-color: var(--color-common-white);
            border-bottom: 0px;
            box-shadow: 0px 0px 3px #00000014 !important;
            border-radius: 5px;
        }
    }
    &.hide-mat-tab-header {
        .mat-mdc-tab-header {
            display: none !important;
        }
    }
}

.editor-template {
    mat-tab-header {
        margin-bottom: 1rem !important;
    }
    .mat-mdc-tab-label[aria-posinset='2'] {
        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
}

.mat-mdc-tab-group {
    &.tab-size-md {
        .mat-mdc-tab-header {
            .mat-mdc-tab-labels .mat-mdc-tab-label {
                min-width: 120px;
            }
        }
    }
}

.mat-mdc-tab-group {
    &.flexible-content-auto-height {
        .mat-mdc-tab-body-content {
            height: auto;
        }
    }
    &.flexible-content {
        height: 100%;
        .mat-mdc-tab-body-wrapper {
            height: 100%;
        }
    }
}

// new-intent-list
.list-menu {
    .mat-mdc-list-base {
        .mat-mdc-list-item {
            height: 35px;
            &:hover {
                color: var(--color-menu-common-hover-link-text) !important;
                background-color: var(--color-menu-common-hover-link-background) !important;
            }
        }
    }
}
