// admin-panel scss (client, payment-logs, plan, service, subscription, email-log, log)
.form-scrollable {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    @media only screen and (max-width: 768px) {
        max-height: calc(100vh - 250px);
    }
}
// admin-panel scss (subscription)
.subscription-filter-menu {
    min-width: 500px !important;
    max-width: 500px !important;
    margin-top: 12px;
    .mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 14px !important;
            .mat-form-field-flex {
                .mat-form-field-infix {
                    .mat-form-field-label-wrapper {
                        top: -18px;
                    }
                }
            }
        }
    }
    .form-scrollable {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        @media only screen and (max-width: 768px) {
            max-height: calc(100vh - 250px);
        }
    }
}
