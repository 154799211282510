// @import '../theme/theme-colors';
// @import '../utils/mixins/common-utils';

.mat-dialog {
    &.mat-dialog-lg {
        .mat-mdc-dialog-container {
            min-width: 800px !important;
        }
    }
    &.mat-dialog-md {
        .mat-dialog-container {
            max-width: 680px !important;
            width: 680px !important;
            padding: 24px !important;
        }
    }
    &.mat-dialog-auto {
        .mat-mdc-dialog-container {
            width: auto !important;
        }
    }
    &.mat-dialog-xlg {
        &.mat-mdc-dialog-full {
            @media only screen and (max-width: 1024px) {
                max-width: 96vw !important;
            }
        }
        .mat-mdc-dialog-container {
            width: 100vw !important;
            padding: 24px !important;
            .mat-mdc-dialog-content {
                max-height: 80vh;
                overflow: hidden;
            }
        }
        &.dialog-no-padding {
            .mat-mdc-dialog-container {
                padding: 0px !important;
                overflow: hidden;
            }
        }
    }
    // &.dialog-no-padding {
    //     .mat-mdc-dialog-container {
    //         padding: 0px !important;
    //         overflow: hidden;
    //     }
    // }
    // &.w-auto {
    //     .mat-mdc-dialog-container {
    //         width: auto !important;
    //     }
    // }
    // &.push-notification-preview {
    //     .mat-mdc-dialog-container {
    //         width: auto !important;
    //         height: auto !important;
    //         margin: 16px;
    //         min-width: 330px;
    //     }
    // }
    // &.video-dialog {
    //     .mat-mdc-dialog-container {
    //         width: 800px !important;
    //         @include media-breakpoint-down('tablet') {
    //             width: calc(100vw - 16px) !important;
    //         }
    //     }
    // }
    // &.network-dailog {
    //     position: absolute !important;
    //     left: 16px;
    //     bottom: 16px;
    //     .mat-mdc-dialog-container {
    //         width: 260px !important;
    //         padding: 16px !important;
    //         p {
    //             line-height: 18px;
    //         }
    //     }
    // }
    &.container-full-width {
        .mat-mdc-dialog-container {
            width: 100% !important;
        }
    }
}

.confirmation-dialog {
    .mat-mdc-dialog-container {
        padding: 24px !important;
    }
}

// // Email Panel
// .templatePreview {
//     .mat-mdc-dialog-container {
//         width: 60vw !important;
//         height: 98vh !important;
//         overflow: hidden;
//         @media screen and (min-width: 768px) and (max-width: 1020px) {
//             width: 80vw !important;
//             height: 90vh !important;
//         }
//         @media only screen and (max-width: 768px) {
//             width: 80vw !important;
//             height: 90vh !important;
//         }
//     }
// }
// .plan-confirm-dialog {
//     .mat-mdc-dialog-container {
//         width: 600px !important;
//         padding: 24px !important;
//         .mat-mdc-dialog-content {
//             max-height: 80vh;
//         }
//     }
// }

// .manage-audio-file {
//     .mat-mdc-dialog-container {
//         width: 700px !important;
//         padding: 24px !important;
//         .mat-mdc-dialog-content {
//             max-height: auto;
//         }
//     }
// }

// // Hello Saved Replied Dialog
// .saved-replies-dialog {
//     .mat-mdc-dialog-container {
//         width: 50vw !important;
//     }
//     .message-tag {
//         .tag-list {
//             max-height: 400px;
//             overflow-y: auto;
//             .mat-mdc-list {
//                 .mat-mdc-list-item-content {
//                     padding: 0px !important;
//                 }
//                 .mat-mdc-list-item {
//                     height: 36px;
//                     cursor: pointer;
//                     &:hover {
//                         background-color: rgba(30, 117, 186, 0.11) !important;
//                         color: var(--color-common-primary);
//                     }
//                 }
//             }
//         }
//         .fr-wrapper {
//             max-height: 100% !important;
//         }
//     }
// }

// // Hello Tag Dialog
// .tag-dialog {
//     .mat-mdc-dialog-container {
//         width: 30vw !important;
//     }
//     .message-tag {
//         .input-loader {
//             position: absolute;
//             right: 0;
//             top: 3px;
//         }
//         .sticky-search {
//             position: sticky;
//             top: 0;
//             z-index: 999;
//             background-color: var(--color-common-white);
//         }
//         .tag-list {
//             .no-tag-found {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 right: 0;
//                 bottom: 0;
//             }
//             .mat-mdc-list {
//                 padding-top: 0px;
//                 .mat-mdc-list-item {
//                     height: 36px;
//                     cursor: pointer;
//                     transition: background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
//                     &:hover {
//                         background-color: rgba(30, 117, 186, 0.11) !important;
//                         color: var(--color-common-primary);
//                     }
//                 }
//             }
//         }
//     }
// }

// // Email Template Dialog
// .create-template-dialog {
//     .mat-mdc-dialog-container {
//         padding: 24px !important;
//     }
// }

// // Default Dialog with 24px padding

// .default-dialog {
//     .mat-mdc-dialog-container {
//         width: 380px !important;
//         padding: 24px !important;
//     }
// }
// .campaign-compose-dry-run-dialog {
//     overflow-y: auto;
// }

// .push-notification-preview {
//     @include media-breakpoint-up('tablet') {
//         &.horizontal {
//             &-center {
//                 + .cdk-global-overlay-wrapper {
//                     justify-content: center !important;
//                 }
//             }
//             &-left {
//                 + .cdk-global-overlay-wrapper {
//                     justify-content: flex-start !important;
//                 }
//             }
//             &-right {
//                 + .cdk-global-overlay-wrapper {
//                     justify-content: flex-end !important;
//                 }
//             }
//         }
//         &.vertical {
//             &-center {
//                 + .cdk-global-overlay-wrapper {
//                     align-items: center !important;
//                 }
//             }
//             &-top {
//                 + .cdk-global-overlay-wrapper {
//                     align-items: flex-start !important;
//                 }
//             }
//             &-bottom {
//                 + .cdk-global-overlay-wrapper {
//                     align-items: flex-end !important;
//                 }
//             }
//         }
//     }
// }

// // admin-panel scss (verify-templates, file, reject-reason)
// .rejection-reason-drop + .cdk-overlay-connected-position-bounding-box {
//     right: 16px !important;
// }
.rejection-reason {
    min-width: 500px !important;
    max-width: 500px !important;
    margin-bottom: 10px;
    &.voice-file-rejection {
        height: 750px !important;
    }
    .mat-mdc-menu-content {
        padding-top: 0 !important;
        .rejection-reason-header {
            padding: 0px 16px;
        }
        .new-reason-form {
            padding: 0px 16px;
            top: 0;
            z-index: 999;
        }
        .rejection-list {
            overflow-y: auto;
            list-style: none;
            margin: 0px;
            padding: 16px;
            .rejection-item {
                margin-bottom: 10px;
                border: 1px dashed var(--color-common-border);
                border-radius: 4px;
                padding: 10px;
                cursor: pointer !important;
                transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
                &:hover {
                    background-color: var(--color-sidenav-menu-hover-link-background) !important;
                    border-color: var(--color-sidenav-menu-hover-link-text);
                    .title,
                    .subtitle,
                    .mat-mdc-delete-btn,
                    .rejection-item-action .mat-mdc-icon {
                        color: var(--color-sidenav-menu-hover-link-text) !important;
                    }
                    .rejection-item-action .mat-mdc-icon {
                        color: var(--color-sidenav-menu-hover-link-text);
                    }
                }
                &.selected {
                    background-color: var(--color-sidenav-menu-active-link-background) !important;
                    border-color: var(--color-sidenav-menu-active-link-text);
                    .title,
                    .subtitle,
                    .mat-mdc-delete-btn {
                        color: var(--color-sidenav-menu-active-link-text) !important;
                    }
                    .rejection-item-action .mat-mdc-icon {
                        color: var(--color-sidenav-menu-hover-link-text);
                    }
                }
            }
        }
    }
    .bottom-seprator {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 13px -6px 13px -7px #00000036;
        position: sticky;
        bottom: 0;
        background-color: var(--color-common-bg);
    }
    .no-record-found {
        height: 366px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// /*---- advance search -----*/
// .mat-mdc-dialog {
//     &.mat-mdc-dialog-lg {
//         &.mat-mdc-dialog-responsive {
//             .mat-mdc-dialog-container {
//                 height: calc(100vh - 312px);
//                 overflow-y: auto;
//             }
//         }
//     }
// }
