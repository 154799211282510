// login button
.google-sign-in {
    padding: 10px 50px !important;
    .mdc-button__label {
        display: flex;
        align-items: center;
    }
}
// Sort Button Only Show on mobile to short table data
.bottom-sort-btn {
    display: none !important;
    @media only screen and (max-width: 768px) {
        display: inline-flex !important;
    }
    .mat-icon {
        @media only screen and (max-width: 660px) {
            margin-inline: 0 !important;
        }
    }
}
