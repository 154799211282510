// Segmento filter

.c-date-picker {
    border: 0px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 29px;
    min-height: 30px !important;
    height: 30px;
    cursor: pointer;
    width: 80px;
    &:focus {
        outline: 0;
    }
}

.c-date-picker-icon {
    .mat-mdc-icon-button {
        line-height: 30px;
        min-height: 30px !important;
        height: 30px;
        width: 26px;
        cursor: pointer;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

// Issue - When we open date time picker first then open date picker and again open date time picker then issue show
// Issue Ref - https://prnt.sc/KgXbFFIdpcih
// Issue Debug::
// Step 1:: I found when we open date time picker then class `mat-calendar-body-cell` default position is relative.
// Step 2:: Now we open date picker then it add position {absolute} & height {0px} on class `mat-calendar-body-cell`.
// Step 3:: Now Again open date time picker. Due to date range picker class `mat-calendar-body-cell` css override
// ------:: and now it's position absolute that's why issue come - https://prnt.sc/KgXbFFIdpcih
// Date Range picker with Time
ngx-mat-datetime-content {
    .mat-mdc-calendar-body-cell {
        position: relative !important;
        .mat-mdc-calendar-body-cell-content {
            font-size: 13.3333px !important ;
        }
    }
}
// Date Range picker
mat-calendar {
    .mat-mdc-calendar-body-cell {
        position: absolute !important;
        height: 100% !important;
    }
}

// date-picker style
.custom-datepicker {
    .date-input {
        font-weight: 400;
        color: var(--color-common-text) !important;
        position: relative;
        line-height: 38px;
        font-size: 14px;
        padding: 0px 12px !important;
        border: 0px;
        position: relative;
        background-color: var(--color-common-white);
        .outline-position {
            outline: 1px solid var(--color-common-border);
            line-height: 40px;
            inset: 0;
        }
        .hide-border {
            background-color: var(--color-common-bg);
            line-height: 40px;
            left: 5px;
            top: -3px;
            height: 6px;
        }
        .float-label {
            position: absolute;
            top: -8px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 14px;
            padding: 0px 5px;
            left: 4px;
            z-index: 99;
            background: linear-gradient(
                to top,
                transparent 0%,
                transparent 40%,
                var(--color-common-white) 40%,
                var(--color-common-white) 60%,
                transparent 60%,
                transparent 100%
            );
        }
        &:hover {
            background-color: var(--color-common-white) !important;
            .outline-position {
                outline: 2px solid var(--color-common-black);
            }
        }
    }
    .today {
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-right: 10px;
        color: var(--color-common-text);
        display: inline-table;
    }
}
.dark-theme {
    .custom-datepicker {
        .date-input {
            background-color: transparent !important;
            .float-label {
                background: linear-gradient(
                    to top,
                    transparent 0%,
                    transparent 40%,
                    var(--color-common-bg) 40%,
                    var(--color-common-bg) 60%,
                    transparent 60%,
                    transparent 100%
                ) !important;
            }
            &:hover {
                .outline-position {
                    outline: 1px solid var(--color-common-grey) !important;
                }
            }
        }
    }
    .mat-mdc-menu-panel {
        .custom-datepicker {
            .date-input {
                .float-label {
                    background: linear-gradient(
                        to top,
                        transparent 0%,
                        transparent 40%,
                        var(--color-common-slate) 40%,
                        var(--color-common-slate) 60%,
                        transparent 60%,
                        transparent 100%
                    ) !important;
                }
            }
        }
    }
}
