.error-toast {
    background-color: var(--color-email-primary) !important;
}
.success-toast {
    background-color: var(--color-common-green) !important;
    color: var(--color-common-white) !important;
}
.mat-mdc-snack-bar-container {
    margin: 4px !important;
    border-radius: 8px;
    min-height: 36px;
    padding: 10px 16px;
    .mat-mdc-simple-snackbar-content {
        font-size: 14px;
        color: var(--color-common-white);
    }
}
.default-snackbar {
    .mat-mdc-simple-snackbar-action .mat-mdc-button {
        color: var(--color-common-white) !important;
        background-color: var(--color-email-primary) !important;
    }
}
